import { onDOMReady, toggleClasses, touchAll } from 'lambda-dom';
void onDOMReady(() => touchAll([
    '.grid-wrapper',
    '.open-close-menu',
], (navigationItems, menuButton) => {
    menuButton.addEventListener('click', () => {
        toggleClasses('show')(menuButton);
        toggleClasses('show')(navigationItems);
    });
}));
