import { getMeta } from 'lambda-dom';
import { defaultTo } from 'ramda';
export var Environment;
(function (Environment) {
    Environment["Local"] = "local";
    Environment["Acceptance"] = "acceptance";
    Environment["Production"] = "production";
    Environment["Unknown"] = "UNKNOWN";
})(Environment || (Environment = {}));
export const environment = defaultTo(Environment.Unknown, getMeta('environment', ensureValidEnvironment));
export const appVersion = getMeta('app-version');
function ensureValidEnvironment(value) {
    return isValidEnvironment(value) ? value : Environment.Unknown;
}
function isValidEnvironment(value) {
    return value === Environment.Local
        || value === Environment.Acceptance
        || value === Environment.Production
        || value === Environment.Unknown;
}
