import Bugsnag from '@bugsnag/js';
import { appVersion, Environment, environment } from '@app-services/environment.service';
// noinspection JSUnusedGlobalSymbols
export const bugsnagClient = Bugsnag.start({
    apiKey: '46e717894e7756c00fac4d5d42cefae0',
    releaseStage: environment,
    enabledReleaseStages: [
        Environment.Acceptance,
        Environment.Production,
        Environment.Unknown,
    ],
    appVersion: appVersion ?? undefined,
});
