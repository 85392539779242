import EventDelegation from '@jjwesterkamp/event-delegation';
import { onDOMReady } from 'lambda-dom';
// Todo: these are not really components, but rather services; components don't run code without
//       explicit calls and/or instantiation. They should only declare.
void onDOMReady(() => {
    EventDelegation
        .global()
        .events('click')
        .select('.location-tr')
        .listen((event) => {
        const dataHref = event.delegator.getAttribute('data-href');
        if (dataHref) {
            window.location.href = dataHref;
        }
    });
});
